<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          商户资金明细表
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->
          <b-button variant="primary" @click="create()" class="mx-2">
            <b-icon icon="plus"></b-icon>
            新增
          </b-button>

          <!--按钮组-->
          <b-button variant="success" @click="edit()" class="mx-2">
            <b-icon icon="pencil"></b-icon>
            编辑
          </b-button>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table
            show-select
            fixed-header
            dense
            height="70vh"
            v-model="table.selected"
            :headers="table.headers"
            :items="table.desserts"
            :single-select="table.singleSelect"
            :footer-props="table.footer"
            :server-items-length="table.itemsCount"
            :options.sync="table.pagination"
            :no-data-text="table.noDataText"
            :loading="table.loading"
            :loading-text="table.loadingText"
            item-key="id"
          >
            <template v-slot:item.customerLevel="{ item }">
              <b-badge class="mr-1" variant="secondary"
                >{{ item.customerLevel }}
              </b-badge>
            </template>

            <template v-slot:item.status="{ item }">
              <h5>
                <b-badge variant="success" v-if="item.status == 1"
                  >正常
                </b-badge>
                <b-badge variant="primary" v-else-if="item.status == 2"
                  >待审核
                </b-badge>
                <b-badge variant="danger" v-else>禁用</b-badge>
              </h5>
            </template>

            <template v-slot:item.sort="{ item }">
              <span>
                <v-btn
                  v-if="!item.edit"
                  small
                  plain
                  v-b-tooltip.hover.lefttop
                  title="双击可修改排序"
                  v-on:click="clickSortField(item)"
                >
                  {{ item.sort }}
                </v-btn>
                <b-input
                  class="w-50"
                  v-if="item.edit"
                  size="sm"
                  v-model="item.sort"
                  v-focus
                  @blur="editSort(item)"
                ></b-input>
              </span>
            </template>

            <!--状态字段   格式处理-->
            <template v-slot:item.action="{ item }">
              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="编辑"
                v-on:click="edit(item.id)"
              >
                <v-icon>la la-edit</v-icon>
              </v-btn>

              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="审核"
                v-if="item.status == 2"
                v-on:click="audit(item)"
              >
                <v-icon>la la-gears</v-icon>
              </v-btn>

              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="禁用"
                v-else-if="item.status == 1"
                v-on:click="changeStatus(item, 0)"
              >
                <v-icon>icon-xl la la-lock</v-icon>
              </v-btn>

              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="启用"
                v-else-if="item.status == 0"
                v-on:click="changeStatus(item, 1)"
              >
                <v-icon>icon-xl la la-lock-open</v-icon>
              </v-btn>
            </template>

            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>
                <b-form-group>
                  <b-form-select
                    :options="filter.conditions"
                    v-model="filter.field"
                  ></b-form-select>
                </b-form-group>
                <label
                  class="after-label ml-2 pb-md-2 pb-lg-2 pb-xl-2 pb-sm-3 pb-3"
                  >:</label
                >
                <b-form-group class="mx-2">
                  <b-form-input
                    id="input-2"
                    v-model="filter.keyword"
                    placeholder="检索关键词"
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select
                    :options="filter.statusOptions"
                    v-model="filter.status"
                  ></b-form-select>
                </b-form-group>

                <b-form-group class="mx-2">
                  <b-button variant="success" v-on:click="getData()">
                    <v-icon small> flaticon-search</v-icon>
                    查询
                  </b-button>
                </b-form-group>
              </b-form>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>



    <!--模态框-审核-->
    <b-modal ref="audit-modal" centered title="商户资金明细表审核">
      <b-row>
        <b-col cols="3" class="text-right">
          <label class="form-group-label">供货商名 :</label>
        </b-col>
        <b-col cols="8">
          <b-form-input
            placeholder=""
            readonly
            disabled="true"
            v-model="current.supplierName"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="3" class="text-right">
          <label class="form-group-label">登录账号 :</label>
        </b-col>
        <b-col cols="8">
          <b-form-input
            placeholder=""
            readonly
            disabled="true"
            name="userName"
            v-model="current.loginAccount"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="3" class="text-right">
          <label class="form-group-label">联系电话 :</label>
        </b-col>
        <b-col cols="8">
          <b-form-input
            placeholder=""
            readonly
            disabled="true"
            v-model="current.contactPhone"
          ></b-form-input>
        </b-col>
      </b-row>

      <!---->
      <template v-slot:modal-footer>
        <b-button v-on:click="submitAudit(1)" variant="primary"
          >通过审核
        </b-button>
        <b-button v-on:click="submitAudit(0)" variant="danger"
          >拒绝申请
        </b-button>
        <b-button v-on:click="$refs['audit-modal'].hide()">取消</b-button>
      </template>
    </b-modal>
    <!--模态框-审核-->
  </div>
</template>

<script>
import Vue from "vue";

import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "List",
  components: {
    KTCard,
  },
  props: {
    title: String,
  },
  data() {
    return {
      auditOptions: [
        { text: "审核通过", value: 1 },
        { text: "拒绝申请", value: 0 },
      ],
      current: {
        supplierName: "",
        userId: null,
        loginAccount: "",
        contactPhone: "",
        supplierId: null,
      },
      filter: {
        conditions: [
          { value: null, text: "检索条件" },
          { value: "supplierName", text: "供货商名称" },
          { value: "loginAccount", text: "登录账号" },
          { value: "contactPhone", text: "联系电话" },
          { value: "email", text: "电子邮箱" },
        ],
        statusOptions: [
          { value: null, text: "状态" },
          { value: 0, text: "禁用" },
          { value: 2, text: "待审核" },
          { value: 1, text: "正常" },
        ],
        field: null,
        keyword: "",
        status: null,
      },
      roleSelected: [],
      submitUrl: "",
      formModel: {
        id: "",
        merchantId: "",
        type: "",
        originAmount: "",
        amount: "",
        createTime: "",
        remark: "",
      },
      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 10,
        headers: [
          { text: "", value: "id" },
          { text: "商户ID", value: "merchantId" },
          { text: "操作类型", value: "type" },
          { text: "原金额", value: "originAmount" },
          { text: "金额", value: "amount" },
          { text: "操作时间", value: "createTime" },
          { text: "订单号", value: "remark" },
          { text: "操作", value: "action" },
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true,
        },
        pagination: {},
        desserts: [],
      },
    };
  },
  mounted() {
    // Create a FormValidation instance
    let MerchantAccountBillsForm = KTUtil.getById("MerchantAccountBillsForm");
    this.fv = formValidation(MerchantAccountBillsForm, {
      fields: {
        id: {
          validators: {
            notEmpty: {
              message: "不能为空!",
            },
          },
        },
        merchantId: {
          validators: {
            notEmpty: {
              message: "商户ID不能为空!",
            },
          },
        },
        type: {
          validators: {
            notEmpty: {
              message: "操作类型:1结算、2提现、3、扣除不能为空!",
            },
          },
        },
        originAmount: {
          validators: {
            notEmpty: {
              message: "原金额不能为空!",
            },
          },
        },
        amount: {
          validators: {
            notEmpty: {
              message: "金额不能为空!",
            },
          },
        },
        createTime: {
          validators: {
            notEmpty: {
              message: "操作时间不能为空!",
            },
          },
        },
        remark: {
          validators: {
            notEmpty: {
              message: "订单号或其他不能为空!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });

    let showModal = this.$route.query.showModal;
    if (showModal == "true") {
      this.create();
    }
  },
  created() {},

  methods: {
    create() {
      this.submitUrl = "/api/merchant/account/bills/create";
      this.formModel = {};

      this.fv.addField("loginAccount", {
        validators: {
          notEmpty: {
            message: "用户名不能为空!",
          },
          remote: {
            message: "此登录账号已存在,不可用!",
            method: "POST",
            url: "/api/user/exist",
          },
        },
      });
      this.$refs["my-modal"].show();
    },
    edit(id = undefined) {
      this.submitUrl = "/api/merchant/account/bills/update";
      id = id == undefined ? this.getSelectedIds() : [id];
      if (id.length != 1) {
        Swal.fire({
          title: "",
          text: "请选择要编辑的数据行,一次仅且只能选择一条!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

      this.fv.addField("loginAccount", {});
      this.fv.removeField("loginAccount");

      let that = this;
      ApiService.query("/api/merchant/account/bills/query_by_id", {
        params: {
          id: id[0],
        },
      }).then(({ data }) => {
        that.formModel = data.result;
      });

      this.$refs["my-modal"].show();
    },

    changeStatus(item, status) {
      if (item == null || item == undefined) {
        Swal.fire({
          title: "",
          text: "请选择要处理的数据行!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }
      this.current.id = item.id;
      this.current.userId = item.userId;
      this.submitAudit(status);
    },

    audit(item = undefined) {
      if (item == null || item == undefined) {
        Swal.fire({
          title: "",
          text: "请选择要审核的数据行!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

      this.current.supplierName = item.supplierName;
      this.current.contactPhone = item.contactPhone;
      this.current.loginAccount = item.loginAccount;
      this.current.supplierId = item.supplierId;
      this.current.userId = item.userId;
      this.$refs["audit-modal"].show();
    },

    getData() {
      
      let params = {
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        status: this.filter.status,
      };
      params["" + this.filter.field + ""] = this.filter.keyword;

      ApiService.query("/api/merchant/account/bills/query_list", {
        params: params,
      }).then(({ data }) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
      });
    },

    getSelectedIds() {
      let ids = [];
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.id);
        });
      }
      return ids;
    },

    onClose() {
      this.$refs["my-modal"].hide();
    },

    onSubmit() {
      let that = this;
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          ApiService.post(that.submitUrl, that.formModel).then(({ data }) => {
            if (data.success) {
              Swal.fire({
                title: "",
                text: "保存成功！",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 1500,
              });
              that.getData();
              that.$refs["my-modal"].hide();
            } else {
              Swal.fire({
                title: "保存失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },
    submitAudit(status) {
      let that = this;
      ApiService.post("/api/merchant/account/bills/change_status", {
        id: that.current.id,
        userId: that.current.userId,
        status: status,
      }).then(({ data }) => {
        if (data.success) {
          Swal.fire({
            title: "",
            text: "提交成功！",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 1500,
          });
          that.getData();
          that.$refs["audit-modal"].hide();
        } else {
          Swal.fire({
            title: "提交失败！",
            text: data.message,
            icon: "warning",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 3000,
          });
        }
      });
    },
  },
  computed: {
    pagination: function () {
      return this.table.pagination;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

